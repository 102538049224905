export const summaryItems = [
  {
    title: (
      <>
        $9.99
        <br />
        Super Sale
      </>
    ),
    imgSrc: "/images/Banner_01.webp",
  },
  {
    title: (
      <>
        Best For
        <br />
        Winter
      </>
    ),
    imgSrc: "/images/Banner_02.webp",
  },
  {
    title: (
      <>
        Baby Care
        <br />
        On Sale
      </>
    ),
    imgSrc: "/images/Banner_03.webp",
  },
];

export const productItems = [
  {
    title: (
      <>
        HEGEN
        <br />
        ESSENTIALS STARTER KIT
      </>
    ),
    imgSrc: "/images/Hegen_01.webp",
    price: 129.99,
    commissions: 3,
    request: ["tt"],
  },
  {
    title: <>B.BOX NEW SIPPY CUP GELATO EDITION</>,
    imgSrc: "/images/B_Box_01.webp",
    price: 15.99,
    commissions: 2.5,
    request: ["ig", "tt"],
  },
  {
    title: (
      <>
        HEGEN PCTO™
        <br />
        240ML/8OZ FEEDING BOTTLE PPSU, 2-PACK
      </>
    ),
    imgSrc: "/images/Hegen_02.webp",
    price: 59.49,
    commissions: 3,
    request: ["tt"],
  },
  {
    title: <>BABY ZIGGY ORGANIC ZEBRA SOFT TOY</>,
    imgSrc: "/images/Baby_Ziggy_01.png",
    price: 17.99,
    commissions: 2.5,
    request: ["yt", "ig", "tt"],
  },
  {
    title: (
      <>
        HEGEN PCTO™
        <br />
        330ML/11OZ DRINKING BOTTLE PPSU PINK
      </>
    ),
    imgSrc: "/images/Hegen_03.webp",
    price: 29.99,
    commissions: 3,
    request: ["ig"],
  },
  {
    title: <>HEGEN PCTO™ FLORAL COLLECTION</>,
    imgSrc: "/images/Hegen_04.webp",
    price: 169.99,
    commissions: 2.5,
    request: ["ig", "tt"],
  },
  {
    title: <>B.BOX MINI LUNCHBOX - STRAWBERRY SHAKE</>,
    imgSrc: "/images/B_Box_02.webp",
    price: 24.99,
    commissions: 2.5,
    request: ["yt", "tt"],
  },
  {
    title: <>B.BOX TODDLER CUTLERY SET - TUTTI FRUTTI</>,
    imgSrc: "/images/B_Box_03.webp",
    price: 17.99,
    commissions: 2.5,
    request: ["yt", "ig", "tt"],
  },
  {
    title: <>HEGEN PCTO™ BASIC STARTER KIT PPSU</>,
    imgSrc: "/images/Hegen_01.webp",
    price: 89.99,
    commissions: 2.5,
    request: ["yt", "tt"],
  },
  {
    title: <>HEGEN PCTO™ BASIC STARTER KIT PPSU</>,
    imgSrc: "/images/B_Box_04.webp",
    price: 18.95,
    commissions: 2.5,
    request: ["yt", "ig", "tt"],
  },
];

export const productLargeItems = [
  {
    imgSrc: "/images/Casting_Dad.jpg",
    title: "V-MAX CASTING",
    text: "Looking for young dad for social video shooting",
  },
  {
    imgSrc: "/images/Casting_Mom.jpg",
    title: "V-MAX CASTING",
    text: "Looking for young mom & baby for social video shooting",
  },
];
