import React from "react";
import { motion } from "framer-motion";
import "./Home.scss";
import {
  summaryItems,
  productItems,
  productLargeItems,
} from "./Home.constants";
import ProductCard from "./ProductCard";
import BouncingImage from "../ui-components/BouncingImage/BouncingImage";

export default function Home() {
  return (
    <div className="page">
      <div className="bg-black"></div>
      <div className="bg-gradient"></div>
      <div className="main-content">
        <div className="title-container">
          <h1 className="welcome-title">Welcome to V-Max Creator Dashboard</h1>
        </div>

        <section className="section summary-section">
          <div className="card summary-card-large">
            <img className="banner-title" src="/images/banner-title.png" />
            <img className="headbanner" src="/images/Headbanner.webp" />
            <BouncingImage
              src="/images/Elements_01.png"
              className="bounce-img-1"
            />
            <BouncingImage
              src="/images/Elements_02.png"
              className="bounce-img-2"
            />
            <BouncingImage
              src="/images/Elements_04.png"
              className="bounce-img-3"
            />
            <BouncingImage
              src="/images/Elements_06.png"
              className="bounce-img-4"
              rotateDeg={70}
            />
            <BouncingImage
              src="/images/Elements_03.png"
              className="bounce-img-5"
            />
            <BouncingImage
              src="/images/Elements_05.png"
              className="bounce-img-6"
            />
            <BouncingImage
              src="/images/Elements_06.png"
              className="bounce-img-7"
            />
            <BouncingImage
              src="/images/Elements_07.png"
              className="bounce-img-8"
            />
            <BouncingImage
              src="/images/Elements_02.png"
              className="bounce-img-9"
            />
            <BouncingImage
              src="/images/Elements_02.png"
              className="bounce-img-10"
            />
          </div>
          {summaryItems.map((summaryItem) => (
            <div className="card summary-card-small">
              <h3 className="summary-item-title">{summaryItem.title}</h3>
              <button> More Detail</button>
              <div className="summary-image-container">
                <motion.div
                  className="summary-image"
                  style={{
                    backgroundImage: `url(${summaryItem.imgSrc})`,
                  }}
                  whileHover={{ scale: [null, 1.15, 1.12] }}
                  transition={{ duration: 0.3 }}
                ></motion.div>
              </div>
            </div>
          ))}
        </section>

        <section className="section product-section">
          <div className="title-container">
            <h1 className="product-title">
              Choose The Product / Content You Like & Earn!
            </h1>
          </div>
          <div className="product-left-container">
            {productItems.slice(0, 6).map((productItem) => (
              <div className="card product-card-small">
                <ProductCard productItem={productItem} />
              </div>
            ))}
          </div>
          <div className="product-right-container">
            {productItems.slice(6, 8).map((productItem) => (
              <div className="card product-card-small">
                <ProductCard productItem={productItem} />
              </div>
            ))}
            {productLargeItems.map((largeItem) => (
              <div className="card product-card-middle">
                <div className="card large-product-container">
                  <div className="large-image-container">
                    <motion.div
                      className="large-image"
                      style={{
                        backgroundImage: `url(${largeItem.imgSrc})`,
                      }}
                      whileHover={{ scale: [null, 1.15, 1.12] }}
                      transition={{ duration: 0.3 }}
                    ></motion.div>
                  </div>
                  <div className="large-item-content">
                    <div>
                      <h3 className="large-item-title">{largeItem.title}</h3>
                      <p className="large-item-text">{largeItem.text}</p>
                    </div>
                    <div className="large-product-button-container">
                      <button>More Detail</button>
                      <button>
                        Add to list <img src="/images/plus_mini_icon.svg"></img>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {productItems.slice(8).map((productItem) => (
              <div className="card product-card-small">
                <ProductCard productItem={productItem} />
              </div>
            ))}
          </div>

          <div className="more-container">
            <img src="/images/arrow_more.png" />
          </div>
        </section>
      </div>
    </div>
  );
}
