import { motion } from "framer-motion";
import React from "react";
import "./BouncingImage.scss";

export default function BouncingImage({ className, src, rotateDeg = 0 }) {
  const getRandomDelay = () => Math.random() * 0.2;
  const getDuration = () => Math.random() * 1 + 1;

  const variants = {
    offscreen: {
      y: -20,
      rotate: rotateDeg,
    },
    onscreen: {
      y: 0,
      rotate: rotateDeg,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: getDuration(),
        delay: getRandomDelay(),
      },
    },
  };

  return (
    <motion.img
      src={src}
      className={className}
      variants={variants}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: false, amount: 0.3 }}
    />
  );
}
