import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./ProductCard.scss";

export default function ProductCard({ productItem }) {
  const [requestImgs, setRequestImgs] = useState(<></>);

  const imageDict = {
    tt: "/images/TT_Square_50x50.png",
    ig: "/images/IG_Square_50x50.png",
    yt: "/images/YT_Square_50x50.png",
  };

  useEffect(() => {
    if (productItem.request) {
      setRequestImgs(
        <>
          {productItem.request.map((req) => (
            <img src={imageDict[req]} />
          ))}
        </>
      );
    }
  }, [productItem]);

  return (
    <div className="product-card-container">
      <div className="product-img-container">
        <motion.img
          src={productItem.imgSrc}
          whileHover={{ scale: [null, 1.15, 1.12] }}
          transition={{ duration: 0.3 }}
        />
      </div>
      <h3 className="product-item-title">{productItem.title}</h3>
      <ul className="product-item-lists">
        <li>
          Price <span>{`${productItem.price} $`}</span>
        </li>
        <li>
          Commissions<span>{`${productItem.commissions} %`}</span>
        </li>
        <li>
          Request<span>{requestImgs}</span>
        </li>
      </ul>
      <div className="button-container">
        <button>More Detail</button>
        <button>
          Add to list <img src="/images/plus_mini_icon.svg"></img>
        </button>
      </div>
    </div>
  );
}
